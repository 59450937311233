<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 资讯分类 </template>
      <template #input>
        <a-button class="all_boder_btn" @click="editFilling(1)">新建</a-button>
      </template>
    </HeaderBox>

        <div class="table-box">
      <a-tabs
        :default-active-key="type"
        @change="callback"
      >
        <a-tab-pane
          key="1"
          tab="首页资讯"
        ></a-tab-pane>
        <a-tab-pane
          key="2"
          tab="教资资讯"
        ></a-tab-pane>
      </a-tabs>
    </div>

    <a-table
      class="table-template"
      @change="onPage"
      :rowKey="(item) => item.newsTypeId"
      :loading="loading"
      :columns="columns"
      :data-source="tableData"
      :pagination="{
        total: total,
        current: pageNumber,
        defaultPageSize: pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return `共${total}条`;
        },
      }"
    >
      <template slot="index" slot-scope="item, row, i">
        {{ (pageNumber - 1) * pageSize + i + 1 }}
      </template>

      <template slot="operation" slot-scope="item, row, index">
        <span class="blueText">
          <span
            @click="moveData(2, index)"
            v-if="!(pageNumber == 1 && index == 0)"
            > 上移 |</span
          >
          <span
            @click="moveData(1, index)"
            v-if="(pageNumber - 1) * pageSize + index + 1 < total"
            >下移 |
          </span>
          <span @click="editFilling(2, row)">编辑</span> |
          <span @click="deleteData(row)">删除</span>
        </span>
      </template>
    </a-table>
    <a-modal
      v-model="edit"
      width="530px"
      title="新建分类"
      :centered="true"
      :closable="false"
    >
      <div class="all_content_box">
        <div class="L_R_inner">
          <span class="all_left_name">分类名称</span>
          <div class="right_Div">
            <a-input
              class="all_input"
              placeholder="请输入分类名称"
              v-model="typeName"
              :max-length="6"
            />
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" :loading="PreventLoad" @click="addEditData()">确认</a-button>
        <a-button @click="editCancel">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "资讯名称",
    align: "center",
    dataIndex: "newsTypeName",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "资讯总数",
    align: "center",
    dataIndex: "newsNum",
  },
  {
    title: "创建时间",
    align: "center",
    dataIndex: "createTime",
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];

export default {
  // 可用组件的哈希表
  components: { HeaderBox }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      type: 1,  //类型 1.首页资讯 2.教资资讯
      columns,
      loading: false,
      state: 1, //1添加 2编辑
      newTypeObj: {}, //分类名称obj
      typeName: "", //分类名称
      tableData: [],
      total: 0,
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      edit: false,
      PreventLoad: false
    };
  }, // 事件处理器
  methods: {
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      // this.getData();
    },
    callback(e) {
      this.type = e;
      this.getData()
    },
    // 资讯分类
    getData() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/news/type/" + this.type,
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.tableData = res.data;
          this.total = res.data.length;
        }
      });
    },
    // 新增/修改
    addEditData() {
      if (!this.typeName) {
        this.$message.warning("请正确填写内容");
        return;
      }
      let url = "";
      let params = {};
      let method = "";
      this.PreventLoad = true;
      // 新增
      if (this.state == 1) {
        url = "/hxclass-management/news/type/?newsTypeName=" + this.typeName + "&platForm=" + this.type;
        method = "post";
        // params = {newsTypeName:this.typeName,platForm:this.type}
      }
      // 编辑
      else if (this.state == 2) {
        url =
          "/hxclass-management/news/type/?newsTypeId=" +
          this.newTypeObj.newsTypeId +
          "&newsTypeName=" +
          this.typeName;
        method = "PUT";
      }

      this.$ajax({
        url,
        method,
      }).then((res) => {
        this.PreventLoad = false;
        if (res.code == 200 && res.success) {
          this.typeName = "";
          this.getData();
          this.edit = false;

          this.$message.success(this.state == 1 ? "新增成功" : "修改成功");
        } else {
          this.edit = false;

          this.$message.warning(res.message);
        }
      });
    },
    // 删除
    deleteData(item) {
      let _this = this;
      // console.log(item.newsNum)
      let confirmTitle = item.newsNum == 0 ? '确认是否删除该分类？' : '删除该分类将会删除该分类下的资讯内容，确认是否删除？'
      this.$confirm({
        title: confirmTitle,
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-management/news/type/" + item.newsTypeId,
              method: "DELETE",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.getData();
                _this.$message.success("删除成功");
              }
            });
        },
        onCancel() {},
      });
    },
    // 上移/下移
    moveData(type, index) {
      let dataIndex = (this.pageNumber - 1) * 10 + index;
      let newsTypeIdFirst = "";
      let newsTypeIdSecond = "";
      // 下移
      if (type == 1) {
        if (dataIndex + 1 == this.tableData.length) {
          this.$message.warning("已经是最后一条数据了");
          return;
        }
        newsTypeIdFirst = this.tableData[dataIndex].newsTypeId;
        newsTypeIdSecond = this.tableData[dataIndex + 1].newsTypeId;
      }
      // 上移
      else if (type == 2) {
        if (dataIndex == 0) {
          this.$message.warning("已经是第一条数据了");
          return;
        }
        newsTypeIdFirst = this.tableData[dataIndex].newsTypeId;
        newsTypeIdSecond = this.tableData[dataIndex - 1].newsTypeId;
      }

      this.$ajax({
        url:
          "/hxclass-management/news/type/move?newsTypeIdFirst=" +
          newsTypeIdFirst +
          "&newsTypeIdSecond=" +
          newsTypeIdSecond,
        method: "PUT",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.getData();
          this.$message.success(type == 1 ? "下移成功" : "上移成功");
        }
      });
    },
    // 打开弹出层
    editFilling(type, item) {
      this.typeName = null;
      // console.log(type, item);
      this.state = type;
      this.newTypeObj = item || {};
      // 修改
      if (type == 2) {
        this.typeName = item.newsTypeName;
      }
      this.edit = true;
    },
    editCancel(e) {
      this.edit = false;
      this.typeName = "";
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    // 资讯分类
    this.getData();
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  min-height: 100%;

  .table-box {
    display: flex;
    justify-content: space-between;

    /deep/ .ant-tabs-bar {
      border-bottom: none;
    }

    /deep/.ant-tabs-bar {
      margin: 0;
    }

    .all_boder_btn {
      margin-top: 10px;
    }
  }
}
.all_content_box {
  .all_input {
    width: 340px;
  }
}
/deep/.ant-modal-header {
  padding: 16px 24px 0px;
  border-bottom: none;
}
/deep/.ant-modal-title {
  text-align: center;
  color: #000000;
}
/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
}
/deep/ .ant-modal-body {
  padding: 24px 24px 14px;
}
/deep/ .ant-btn {
  margin-right: 24px;
}
.blueText span {
  cursor: pointer;
}
</style>
