var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 资讯分类 ")]},proxy:true},{key:"input",fn:function(){return [_c('a-button',{staticClass:"all_boder_btn",on:{"click":function($event){return _vm.editFilling(1)}}},[_vm._v("新建")])]},proxy:true}])}),_c('div',{staticClass:"table-box"},[_c('a-tabs',{attrs:{"default-active-key":_vm.type},on:{"change":_vm.callback}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"首页资讯"}}),_c('a-tab-pane',{key:"2",attrs:{"tab":"教资资讯"}})],1)],1),_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item) { return item.newsTypeId; },"loading":_vm.loading,"columns":_vm.columns,"data-source":_vm.tableData,"pagination":{
      total: _vm.total,
      current: _vm.pageNumber,
      defaultPageSize: _vm.pageSize,
      showSizeChanger: true,
      showTotal: function (total, range) {
        return ("共" + total + "条");
      },
    }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, i){return [_vm._v(" "+_vm._s((_vm.pageNumber - 1) * _vm.pageSize + i + 1)+" ")]}},{key:"operation",fn:function(item, row, index){return [_c('span',{staticClass:"blueText"},[(!(_vm.pageNumber == 1 && index == 0))?_c('span',{on:{"click":function($event){return _vm.moveData(2, index)}}},[_vm._v(" 上移 |")]):_vm._e(),((_vm.pageNumber - 1) * _vm.pageSize + index + 1 < _vm.total)?_c('span',{on:{"click":function($event){return _vm.moveData(1, index)}}},[_vm._v("下移 | ")]):_vm._e(),_c('span',{on:{"click":function($event){return _vm.editFilling(2, row)}}},[_vm._v("编辑")]),_vm._v(" | "),_c('span',{on:{"click":function($event){return _vm.deleteData(row)}}},[_vm._v("删除")])])]}}])}),_c('a-modal',{attrs:{"width":"530px","title":"新建分类","centered":true,"closable":false},model:{value:(_vm.edit),callback:function ($$v) {_vm.edit=$$v},expression:"edit"}},[_c('div',{staticClass:"all_content_box"},[_c('div',{staticClass:"L_R_inner"},[_c('span',{staticClass:"all_left_name"},[_vm._v("分类名称")]),_c('div',{staticClass:"right_Div"},[_c('a-input',{staticClass:"all_input",attrs:{"placeholder":"请输入分类名称","max-length":6},model:{value:(_vm.typeName),callback:function ($$v) {_vm.typeName=$$v},expression:"typeName"}})],1)])]),_c('template',{slot:"footer"},[_c('a-button',{attrs:{"type":"primary","loading":_vm.PreventLoad},on:{"click":function($event){return _vm.addEditData()}}},[_vm._v("确认")]),_c('a-button',{on:{"click":_vm.editCancel}},[_vm._v("取消")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }